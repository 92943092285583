/**
 * @typedef {import('./lib/index.js').Options} Options
 */

export {
  defaultFootnoteBackContent,
  defaultFootnoteBackLabel,
  defaultHandlers
} from 'mdast-util-to-hast'
export {default} from './lib/index.js'
