import { Helmet } from 'react-helmet';
import GetImageSize from '../../utilities/getimagesize/getimagesize';

function Meta(props: any) {

    return (
        <Helmet>
            {props.content.title &&
                <title>{props.content.title}</title>    
            }
            {props.content.title &&
                <meta property="og:title" content="How to Become an SEO Expert (8 Steps)" />
            }
            {props.content.description &&
                <meta name='description' content={props.content.description} />
            }  
            {props.content.description &&  
                <meta property="og:description" content={props.content.description} />
            }
            {props.content.image && props.content.image.data &&
                <meta property="og:image" content={GetImageSize(props.content.image, 'large')} />
            }
        </Helmet>
    );
}

export default Meta;